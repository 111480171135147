<script setup>
import { Head, Link } from '@inertiajs/vue3';
import { logo } from '@/../images';
import { Icons } from '@goodvibes/kudoslink';
import { Companies } from '@/../images';
import { computed } from 'vue';
import { JoinByCode } from '@/Components';

defineProps({
    pageTitle: String,
});

const currentUrl = computed(() => window.location.href);
</script>

<template>
    <Head v-if="pageTitle" :title="pageTitle" />

    <header
        class="lg:flex lg:justify-between mt-12 lg:mt-5 mb-5 lg:my-10 lg:mx-10 lg:items-center space-y-5 lg:space-y-0"
    >
        <a :href="route('landingpage')">
            <img :src="logo" class="w-36 mx-auto lg:mx-0" alt="feedbeo logo" />
        </a>
        <JoinByCode class="lg:max-h-20" />
        <Link
            :href="currentUrl == route('login') ? route('register') : route('login')"
            class="hidden lg:block btn btn--tertiary btn--narrow btn--outline border-[#6212E5] text-[#6212E5] px-10 py-3"
        >
            {{ currentUrl == route('login') ? 'Sign up' : 'Log in' }}
        </Link>
    </header>

    <div class="flex flex-col lg:flex-row px-4 sm:px-0 mx-auto gap-x-10 w-fit max-w-md lg:max-w-none">
        <slot name="leftColumn">
            <div class="hidden xl:flex flex-col mt-32 shrink-0 w-fit max-w-xs">
                <span class="text-[#3F4A55] font-medium text-sm uppercase">Easy to set up — promise!</span>
                <div class="flex items-baseline gap-x-5 mt-8">
                    <Icons.CheckIcon class="fill-[#6212E5] w-4" />
                    <div>
                        <p class="font-bold text-2xl">Get Started Quickly</p>
                        <p class="text-[#8C96A1] font-medium text-sm">Setup for hosts takes only a few clicks</p>
                    </div>
                </div>
                <div class="flex items-baseline gap-x-5 mt-8">
                    <Icons.CheckIcon class="fill-[#6212E5] w-4" />
                    <div>
                        <p class="font-bold text-2xl">Simple and intuitive</p>
                        <p class="text-[#8C96A1] font-medium text-sm">
                            Attendees can join without any logins or downloads.
                        </p>
                    </div>
                </div>
                <div class="flex items-baseline gap-x-5 mt-8">
                    <Icons.CheckIcon class="fill-[#6212E5] w-4" />
                    <div>
                        <p class="font-bold text-2xl">Free forever</p>
                        <p class="text-[#8C96A1] font-medium text-sm">100% free. No credit card needed.</p>
                    </div>
                </div>
            </div>
        </slot>

        <slot name="default"></slot>

        <slot name="rightColumn">
            <div class="flex flex-col shrink-0 mt-14 lg:mt-32 w-full lg:w-fit lg:max-w-xs">
                <span class="text-[#3F4A55] font-medium text-sm uppercase mx-auto lg:mx-0">Trusted by</span>
                <div class="flex flex-wrap justify-center lg:justify-normal mx-auto lg:mx-0 gap-5">
                    <img v-for="company in Companies" class="mt-8 w-32" :src="company" />
                </div>
            </div>
        </slot>
    </div>
</template>
