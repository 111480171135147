<script setup>
import { TextInput } from '@goodvibes/kudoslink';
import { useForm } from '@inertiajs/vue3';

const form = useForm({
    code: '',
});
function submit() {
    form.get(route('meetings.join'));
}
</script>

<template>
    <div
        class="bg-[#DAF7E8] rounded-xl px-4 py-4 lg:px-6 lg:py-4 flex lg:flex-row flex-col items-center gap-y-5 lg:gap-y-0 lg:gap-x-5 mx-3 lg:mx-0"
    >
        <div class="flex flex-col text-center lg:text-left">
            <p>Joining as participant?</p>
            <p class="text-xs">No account needed</p>
        </div>
        <TextInput
            v-model:modelValue="form.code"
            inputClass="input--small"
            maxlength="12"
            placeholder="Enter 6-digit event code"
        />
        <button
            type="button"
            @click="submit"
            class="btn flex items-center btn--narrow text-[#ffff] bg-[#14C469] hover:bg-[#11AC5C]"
        >
            Join
        </button>
    </div>
</template>
